<template>
  <div id="exportDialog">
    <el-dialog
        title="导出报表"
        :visible.sync="show"
        width="500px"
        @closed="reset"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <el-form-item label="时间范围" prop="time">
          <el-date-picker
              v-model="form.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="confirm" :loading="loading">导 出</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      loading:false,
      form: {
        time: "",
      },
      rules: {
        time: [
          {required: true, validator: this.checkTime, trigger: 'blur'}
        ],
      },
    }
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    checkTime(rule, value, callback) {
      for (let item of value) {
        if (item === "") {
          callback(new Error('请选择时间范围'));
          return;
        }
      }
      callback();
    },
    reset() {
      this.$refs["form"].resetFields();
    },
    confirm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading=true
          let startTime = this.form.time[0];
          let endTime = this.form.time[1];

          try {
            await this.$http.post("/freeCar/order/report", {
              startTime,
              endTime,
              merchantId: this.$route.params.id,
            }, {
              responseType: 'blob',
              timeout:0
            });
            this.loading=false
          } catch {
            this.$message.error("导出失败");
            this.loading=false
          }
        }
      });

    },
    handleShow() {
      this.show = !this.show;
    }
  }
}
</script>

<style lang="scss">
#exportDialog {
  .el-date-editor,
  .el-select {
    width: 100%;
  }
}
</style>
